import GatewayBase from '~/support/services/gateway-base';

export interface Brand {
  id: string;
  name: string;
  clientId: string;
  url: string;
  currentVersion: string;
  deployments?: any[];
}

export class ImplementationRestService extends GatewayBase {
  get brands() {
    return {
      create: this.createBrand,
      delete: this.deleteBrand,
      deployments: {
        list: this.listBrandDeployments,
      },
      get: this.getBrand,
      list: this.listBrands,
      update: this.updateBrand,
    };
  }

  get deployments() {
    return {
      comments: {
        create: this.createComment,
        load: this.loadComments,
      },
      create: this.createDeployment,
      get: this.getDeployment,
      list: this.listDeployments,
      review: {
        create: this.createReview,
      },
      start: this.startDeployment,
    };
  }

  get versions() {
    return {
      list: this.listVersions,
    };
  }

  private createComment = (
    id: string,
    body: { message: string; authorName: string; authorImage: string; authorId: string },
  ) => {
    return this.client.post(`/v1/implementation/deployments/${id}/comments`, body);
  };

  private loadComments = (id: string) => {
    return this.client.get<
      {
        id: string;
        message: string;
        authorName: string;
        authorImage: string;
        authorId: string;
        createdAt: string;
      }[]
    >(`/v1/implementation/deployments/${id}/comments`);
  };

  private listBrandDeployments = (id: string) => {
    return this.client.get<
      {
        oldVersion: string;
        newVersion: string;
        deployment: {
          id: string;
          name: string;
          description?: string;
          createdAt: string;
          updatedAt: string;
          approvalStatus: string;
          status: string;
          authorName: string;
          authorId: string;
        };
      }[]
    >(`/v1/implementation/brands/${id}/deployments`);
  };

  private createReview = (
    id: string,
    body: {
      comment?: string;
      resolution: 'approved' | 'rejected';
      reviewerId: string;
      reviewerName: string;
    },
  ) => {
    return this.client.post(`/v1/implementation/deployments/${id}/reviews`, body);
  };

  private startDeployment = (id: string, body: { startedById: string; startedByName: string }) => {
    return this.client.post(`/v1/implementation/deployments/${id}/deploy`, body);
  };

  private listVersions = () => {
    return this.client.get<
      {
        url: string;
        name: string;
        commit: string;
      }[]
    >('/v1/implementation/tags');
  };

  private createDeployment = (body: {
    name: string;
    version: string;
    brands: string[];
    authorId: string;
    authorName: string;
    description?: string;
  }) => {
    return this.client.post<{
      id: string;
      name: string;
      version: string;
      brands: string[];
      authorId: string;
      authorName: string;
      description?: string;
    }>('/v1/implementation/deployments', body);
  };

  private listDeployments = () => {
    return this.client.get<
      {
        id: string;
        name: string;
        version: string;
        brands: { name: string; id: string; currentVersion: string }[];
        authorId: string;
        authorName: string;
        description?: string;
        reviews: {
          createdAt: string;
          authorName: string;
          status: string;
        }[];
      }[]
    >('/v1/implementation/deployments');
  };

  private getDeployment = (id: string) => {
    return this.client.get<{
      id: string;
      name: string;
      version: string;
      brands: string[];
      authorId: string;
      authorName: string;
      description?: string;
    }>(`/v1/implementation/deployments/${id}`);
  };

  private createBrand = (body: { name: string; url: string; clientId: string; myShopifyDomain: string }) => {
    return this.client.post<{
      id: string;
      name: string;
      clientId: string;
      deployments?: any[];
      url: string;
      myShopifyDomain: string;
    }>('/v1/implementation/brands', body);
  };

  private listBrands = () => {
    return this.client.get<Brand[]>('/v1/implementation/brands');
  };

  private getBrand = (id: string) => {
    return this.client.get(`/v1/implementation/brands/${id}`);
  };

  private updateBrand = (id: string, body: { name: string; url: string; clientId: string }) => {
    return this.client.put(`/v1/implementation/brands/${id}`, body);
  };

  private deleteBrand = (id: string) => {
    return this.client.delete(`/v1/implementation/brands/${id}`);
  };
}
