
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg fill="#000000" width="800px" height="800px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
    <path d="M1740 0c99.24 0 180 80.76 180 180v1560c0 99.24-80.76 180-180 180H180c-99.24 0-180-80.76-180-180V180C0 80.76 80.76 0 180 0h1560Zm60 1740V180c0-33-26.88-60-60-60H720v1680h1020c33.12 0 60-27 60-60Zm-1620 60h420v-480H120v420c0 33 26.88 60 60 60Zm-60-600h480V720H120v480Zm60-1080c-33.12 0-60 27-60 60v420h480V120H180Zm963.516 390h240v330h330v240h-330v330h-240v-330h-330V840h330V510Z" fill-rule="evenodd"/>
</svg>

  </template>

  <script>
  export default {
    name: 'TableInsertColumnAfter',
    inheritAttrs: true,
  }
  </script>
