import { default as indexWQn7ruwB1yMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/admin/index.vue?macro=true";
import { default as indexGE9pUoQMbmMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/admin/payments/index.vue?macro=true";
import { default as indexS32Kk59F5JMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/admin/payouts/index.vue?macro=true";
import { default as admineGD1YPzaqnMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/admin.vue?macro=true";
import { default as indexkSmHfam21MMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payments/index.vue?macro=true";
import { default as indexBMpKcFzCb4Meta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payments/invoices/index.vue?macro=true";
import { default as _91id_93M0vbwyLhidMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payments/invoices/view/[id].vue?macro=true";
import { default as indexJ8SsB8MubTMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payments/manage-by-store/index.vue?macro=true";
import { default as indexpQtIgbPhJAMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payments/payment-methods/index.vue?macro=true";
import { default as paymentsS9ur97XOAeMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payments.vue?macro=true";
import { default as discountsExFfA6loNwMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id]/discounts.vue?macro=true";
import { default as not_45reimbursedMLOZrPhFt0Meta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id]/not-reimbursed.vue?macro=true";
import { default as reimbursed40fj5TakUBMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id]/reimbursed.vue?macro=true";
import { default as revenue_45shareS5kvmCgl2iMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id]/revenue-share.vue?macro=true";
import { default as _91id_93beWOErWA97Meta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id].vue?macro=true";
import { default as indexMA9HNU16R1Meta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payouts/index.vue?macro=true";
import { default as payoutsSwFAsoV4RUMeta } from "/app/apps/valet/src/pages/[storeSlug]/finance/payouts.vue?macro=true";
import { default as _91type_93EojiLaqyLzMeta } from "/app/apps/valet/src/pages/[storeSlug]/macros/[type].vue?macro=true";
import { default as indexSGLidQC8NsMeta } from "/app/apps/valet/src/pages/[storeSlug]/macros/index.vue?macro=true";
import { default as payouts3RowdJUel8Meta } from "/app/apps/valet/src/pages/[storeSlug]/payouts.vue?macro=true";
import { default as indexOKAkOqprxFMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/api-tokens/index.vue?macro=true";
import { default as brandingeQP8zq049sMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/branding.vue?macro=true";
import { default as capabilities6elwUcUVH1Meta } from "/app/apps/valet/src/pages/[storeSlug]/settings/capabilities.vue?macro=true";
import { default as claimsYVeNJsJeIJMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/claims.vue?macro=true";
import { default as contactidseHktUwXMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/contact.vue?macro=true";
import { default as contractsysOC9hGW6dMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/contracts.vue?macro=true";
import { default as developer96JH7ZNnqcMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/developer.vue?macro=true";
import { default as generalSp40W9bVxnMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/general.vue?macro=true";
import { default as indexoMenywjPRtMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/index.vue?macro=true";
import { default as integrationsmfpcxcB8jAMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/integrations.vue?macro=true";
import { default as policiesml2RGYu0VsMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/policies.vue?macro=true";
import { default as indexfK3QwIK3ZbMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/sops/index.vue?macro=true";
import { default as warrantyHJeAYT3FITMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/warranty.vue?macro=true";
import { default as indexZP6Fg67eCiMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/webhooks/index.vue?macro=true";
import { default as _91id_93BAJ6N2HXZCMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings/webhooks/view/[id].vue?macro=true";
import { default as settingsdUtcbkhyqbMeta } from "/app/apps/valet/src/pages/[storeSlug]/settings.vue?macro=true";
import { default as _91id_93UL07pkkKt6Meta } from "/app/apps/valet/src/pages/[storeSlug]/sops/[id].vue?macro=true";
import { default as activeOgYMwPAQERMeta } from "/app/apps/valet/src/pages/[storeSlug]/team-members/active.vue?macro=true";
import { default as inactives0DaWaotoiMeta } from "/app/apps/valet/src/pages/[storeSlug]/team-members/inactive.vue?macro=true";
import { default as indexp3VH6SBtTbMeta } from "/app/apps/valet/src/pages/[storeSlug]/team-members/index.vue?macro=true";
import { default as _91id_93tWyPfbwuejMeta } from "/app/apps/valet/src/pages/[storeSlug]/team-members/member/[id].vue?macro=true";
import { default as team_45membersFXgPCUloKyMeta } from "/app/apps/valet/src/pages/[storeSlug]/team-members.vue?macro=true";
import { default as indexrmTXRnSL9vMeta } from "/app/apps/valet/src/pages/[storeSlug]/tests/index.vue?macro=true";
import { default as _91id_93v0mAsEDtOuMeta } from "/app/apps/valet/src/pages/[storeSlug]/tests/view/[id].vue?macro=true";
import { default as _91type_93qU6lRaK4u4Meta } from "/app/apps/valet/src/pages/admin/global-macros/[type].vue?macro=true";
import { default as indexzTUzRi8Sp0Meta } from "/app/apps/valet/src/pages/admin/global-macros/index.vue?macro=true";
import { default as _91id_936pgR4OX7bBMeta } from "/app/apps/valet/src/pages/admin/implementation/brands/[id].vue?macro=true";
import { default as index2Hx49y9bozMeta } from "/app/apps/valet/src/pages/admin/implementation/brands/index.vue?macro=true";
import { default as _91id_93Q9sJ6FmjWHMeta } from "/app/apps/valet/src/pages/admin/implementation/deployments/[id].vue?macro=true";
import { default as indexnLnoUXfrtNMeta } from "/app/apps/valet/src/pages/admin/implementation/deployments/index.vue?macro=true";
import { default as implementationdVXrQFAknMMeta } from "/app/apps/valet/src/pages/admin/implementation.vue?macro=true";
import { default as macros5xNrJ4ubn3Meta } from "/app/apps/valet/src/pages/admin/macros.vue?macro=true";
import { default as activeELNvCcCPS6Meta } from "/app/apps/valet/src/pages/admin/stores/active.vue?macro=true";
import { default as devKsrs6wAcCtMeta } from "/app/apps/valet/src/pages/admin/stores/dev.vue?macro=true";
import { default as inactiveElFGYe5W0yMeta } from "/app/apps/valet/src/pages/admin/stores/inactive.vue?macro=true";
import { default as index1puAZh9BPMMeta } from "/app/apps/valet/src/pages/admin/stores/index.vue?macro=true";
import { default as onboardingUyCXevX9UvMeta } from "/app/apps/valet/src/pages/admin/stores/onboarding.vue?macro=true";
import { default as storesc0wtJ2ZaVTMeta } from "/app/apps/valet/src/pages/admin/stores.vue?macro=true";
import { default as _91type_93lMwbHYaisRMeta } from "/app/apps/valet/src/pages/admin/users/[type].vue?macro=true";
import { default as indexAPdr0f8YIhMeta } from "/app/apps/valet/src/pages/admin/users/index.vue?macro=true";
import { default as _91id_93m8uzBMhMeOMeta } from "/app/apps/valet/src/pages/admin/users/user/[id].vue?macro=true";
import { default as usersL3in0c9UwPMeta } from "/app/apps/valet/src/pages/admin/users.vue?macro=true";
import { default as indexgMTz97nFbiMeta } from "/app/apps/valet/src/pages/admin/whitelabel/index.vue?macro=true";
import { default as whitelabelEBWbHVoBXUMeta } from "/app/apps/valet/src/pages/admin/whitelabel.vue?macro=true";
import { default as _91type_93Q5WUEoRL9TMeta } from "/app/apps/valet/src/pages/claims/[type].vue?macro=true";
import { default as indexHkXFeGch4QMeta } from "/app/apps/valet/src/pages/claims/index.vue?macro=true";
import { default as _91id_93O8cZFWuSc6Meta } from "/app/apps/valet/src/pages/claims/view/[id].vue?macro=true";
import { default as claimsP2G3Mels63Meta } from "/app/apps/valet/src/pages/claims.vue?macro=true";
import { default as _91id_93ZwG3bg8qFiMeta } from "/app/apps/valet/src/pages/customers/[id].vue?macro=true";
import { default as indexM07iimR40CMeta } from "/app/apps/valet/src/pages/dashboard/index.vue?macro=true";
import { default as overviewpixbpEFc94Meta } from "/app/apps/valet/src/pages/dashboard/overview.vue?macro=true";
import { default as dashboardLo4QSk7jTkMeta } from "/app/apps/valet/src/pages/dashboard.vue?macro=true";
import { default as indexstNItgaC36Meta } from "/app/apps/valet/src/pages/index.vue?macro=true";
import { default as callbackHquhJgOurbMeta } from "/app/apps/valet/src/pages/login/callback.vue?macro=true";
import { default as indexzxcf67RnbkMeta } from "/app/apps/valet/src/pages/login/index.vue?macro=true";
import { default as not_45foundpZXsfmGS2rMeta } from "/app/apps/valet/src/pages/login/not-found.vue?macro=true";
import { default as resete0ME7RAFInMeta } from "/app/apps/valet/src/pages/login/reset.vue?macro=true";
import { default as set_45passworde06Vh6way0Meta } from "/app/apps/valet/src/pages/login/set-password.vue?macro=true";
import { default as welcome_46test1PMTcOABqgMeta } from "/app/apps/valet/src/pages/login/welcome.test.ts?macro=true";
import { default as welcomexUye6lIWzWMeta } from "/app/apps/valet/src/pages/login/welcome.vue?macro=true";
import { default as loginXuUDFXbT8eMeta } from "/app/apps/valet/src/pages/login.vue?macro=true";
import { default as indexzYZYZFfsOfMeta } from "/app/apps/valet/src/pages/orders/index.vue?macro=true";
import { default as _91type_93gZjL9lKqnOMeta } from "/app/apps/valet/src/pages/store-credit/[type].vue?macro=true";
import { default as index4PvaHPyIn9Meta } from "/app/apps/valet/src/pages/store-credit/index.vue?macro=true";
import { default as store_45creditMDe6yHOjqUMeta } from "/app/apps/valet/src/pages/store-credit.vue?macro=true";
import { default as unauthorizedImIOFdzRSwMeta } from "/app/apps/valet/src/pages/unauthorized.vue?macro=true";
export default [
  {
    name: admineGD1YPzaqnMeta?.name,
    path: "/:storeSlug()/finance/admin",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-finance-admin___en",
    path: "",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-admin-payments___en",
    path: "payments",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/admin/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-admin-payouts___en",
    path: "payouts",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/admin/payouts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: paymentsS9ur97XOAeMeta?.name,
    path: "/:storeSlug()/finance/payments",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payments.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-finance-payments___en",
    path: "",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payments-invoices___en",
    path: "invoices",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payments/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payments-invoices-view-id___en",
    path: "invoices/view/:id()",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payments/invoices/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payments-manage-by-store___en",
    path: "manage-by-store",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payments/manage-by-store/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payments-payment-methods___en",
    path: "payment-methods",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payments/payment-methods/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: payoutsSwFAsoV4RUMeta?.name,
    path: "/:storeSlug()/finance/payouts",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payouts.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-finance-payouts-id___en",
    path: ":id()",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-finance-payouts-id-discounts___en",
    path: "discounts",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id]/discounts.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payouts-id-not-reimbursed___en",
    path: "not-reimbursed",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id]/not-reimbursed.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payouts-id-reimbursed___en",
    path: "reimbursed",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id]/reimbursed.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payouts-id-revenue-share___en",
    path: "revenue-share",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payouts/[id]/revenue-share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storeSlug-finance-payouts___en",
    path: "",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/finance/payouts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storeSlug-macros-type___en",
    path: "/:storeSlug()/macros/:type()",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/macros/[type].vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-macros___en",
    path: "/:storeSlug()/macros",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/macros/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-payouts___en",
    path: "/:storeSlug()/payouts",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/payouts.vue").then(m => m.default || m)
  },
  {
    name: settingsdUtcbkhyqbMeta?.name,
    path: "/:storeSlug()/settings",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-settings-api-tokens___en",
    path: "api-tokens",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/api-tokens/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-branding___en",
    path: "branding",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/branding.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-capabilities___en",
    path: "capabilities",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/capabilities.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-claims___en",
    path: "claims",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/claims.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-contact___en",
    path: "contact",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/contact.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-contracts___en",
    path: "contracts",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/contracts.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-developer___en",
    path: "developer",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/developer.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-general___en",
    path: "general",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings___en",
    path: "",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-integrations___en",
    path: "integrations",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-policies___en",
    path: "policies",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/policies.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-sops___en",
    path: "sops",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/sops/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-warranty___en",
    path: "warranty",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/warranty.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-webhooks___en",
    path: "webhooks",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/webhooks/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-webhooks-view-id___en",
    path: "webhooks/view/:id()",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/settings/webhooks/view/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storeSlug-sops-id___en",
    path: "/:storeSlug()/sops/:id()",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/sops/[id].vue").then(m => m.default || m)
  },
  {
    name: team_45membersFXgPCUloKyMeta?.name,
    path: "/:storeSlug()/team-members",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/team-members.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-team-members-active___en",
    path: "active",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/team-members/active.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-team-members-inactive___en",
    path: "inactive",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/team-members/inactive.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-team-members___en",
    path: "",
    meta: indexp3VH6SBtTbMeta || {},
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/team-members/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-team-members-member-id___en",
    path: "member/:id()",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/team-members/member/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storeSlug-tests___en",
    path: "/:storeSlug()/tests",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/tests/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-tests-view-id___en",
    path: "/:storeSlug()/tests/view/:id()",
    component: () => import("/app/apps/valet/src/pages/[storeSlug]/tests/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-global-macros-type___en",
    path: "/admin/global-macros/:type()",
    component: () => import("/app/apps/valet/src/pages/admin/global-macros/[type].vue").then(m => m.default || m)
  },
  {
    name: "admin-global-macros___en",
    path: "/admin/global-macros",
    component: () => import("/app/apps/valet/src/pages/admin/global-macros/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-implementation___en",
    path: "/admin/implementation",
    component: () => import("/app/apps/valet/src/pages/admin/implementation.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-implementation-brands-id___en",
    path: "brands/:id()",
    component: () => import("/app/apps/valet/src/pages/admin/implementation/brands/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-implementation-brands___en",
    path: "brands",
    component: () => import("/app/apps/valet/src/pages/admin/implementation/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-implementation-deployments-id___en",
    path: "deployments/:id()",
    component: () => import("/app/apps/valet/src/pages/admin/implementation/deployments/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-implementation-deployments___en",
    path: "deployments",
    component: () => import("/app/apps/valet/src/pages/admin/implementation/deployments/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-macros___en",
    path: "/admin/macros",
    component: () => import("/app/apps/valet/src/pages/admin/macros.vue").then(m => m.default || m)
  },
  {
    name: storesc0wtJ2ZaVTMeta?.name,
    path: "/admin/stores",
    component: () => import("/app/apps/valet/src/pages/admin/stores.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-stores-active___en",
    path: "active",
    component: () => import("/app/apps/valet/src/pages/admin/stores/active.vue").then(m => m.default || m)
  },
  {
    name: "admin-stores-dev___en",
    path: "dev",
    component: () => import("/app/apps/valet/src/pages/admin/stores/dev.vue").then(m => m.default || m)
  },
  {
    name: "admin-stores-inactive___en",
    path: "inactive",
    component: () => import("/app/apps/valet/src/pages/admin/stores/inactive.vue").then(m => m.default || m)
  },
  {
    name: "admin-stores___en",
    path: "",
    component: () => import("/app/apps/valet/src/pages/admin/stores/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-stores-onboarding___en",
    path: "onboarding",
    component: () => import("/app/apps/valet/src/pages/admin/stores/onboarding.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersL3in0c9UwPMeta?.name,
    path: "/admin/users",
    component: () => import("/app/apps/valet/src/pages/admin/users.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-users-type___en",
    path: ":type()",
    component: () => import("/app/apps/valet/src/pages/admin/users/[type].vue").then(m => m.default || m)
  },
  {
    name: "admin-users___en",
    path: "",
    component: () => import("/app/apps/valet/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-user-id___en",
    path: "user/:id()",
    component: () => import("/app/apps/valet/src/pages/admin/users/user/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: whitelabelEBWbHVoBXUMeta?.name,
    path: "/admin/whitelabel",
    component: () => import("/app/apps/valet/src/pages/admin/whitelabel.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-whitelabel___en",
    path: "",
    component: () => import("/app/apps/valet/src/pages/admin/whitelabel/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: claimsP2G3Mels63Meta?.name,
    path: "/claims",
    component: () => import("/app/apps/valet/src/pages/claims.vue").then(m => m.default || m),
    children: [
  {
    name: "claims-type___en",
    path: ":type()",
    component: () => import("/app/apps/valet/src/pages/claims/[type].vue").then(m => m.default || m)
  },
  {
    name: "claims___en",
    path: "",
    meta: indexHkXFeGch4QMeta || {},
    component: () => import("/app/apps/valet/src/pages/claims/index.vue").then(m => m.default || m)
  },
  {
    name: "claims-view-id___en",
    path: "view/:id()",
    component: () => import("/app/apps/valet/src/pages/claims/view/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "customers-id___en",
    path: "/customers/:id()",
    component: () => import("/app/apps/valet/src/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: dashboardLo4QSk7jTkMeta?.name,
    path: "/dashboard",
    component: () => import("/app/apps/valet/src/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard___en",
    path: "",
    meta: indexM07iimR40CMeta || {},
    component: () => import("/app/apps/valet/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-overview___en",
    path: "overview",
    component: () => import("/app/apps/valet/src/pages/dashboard/overview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/apps/valet/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginXuUDFXbT8eMeta?.name,
    path: "/login",
    component: () => import("/app/apps/valet/src/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: "login-callback___en",
    path: "callback",
    component: () => import("/app/apps/valet/src/pages/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "",
    meta: indexzxcf67RnbkMeta || {},
    component: () => import("/app/apps/valet/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-not-found___en",
    path: "not-found",
    component: () => import("/app/apps/valet/src/pages/login/not-found.vue").then(m => m.default || m)
  },
  {
    name: "login-reset___en",
    path: "reset",
    component: () => import("/app/apps/valet/src/pages/login/reset.vue").then(m => m.default || m)
  },
  {
    name: "login-set-password___en",
    path: "set-password",
    component: () => import("/app/apps/valet/src/pages/login/set-password.vue").then(m => m.default || m)
  },
  {
    name: "login-welcome.test___en",
    path: "welcome.test",
    component: () => import("/app/apps/valet/src/pages/login/welcome.test.ts").then(m => m.default || m)
  },
  {
    name: "login-welcome___en",
    path: "welcome",
    meta: welcomexUye6lIWzWMeta || {},
    component: () => import("/app/apps/valet/src/pages/login/welcome.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orders___en",
    path: "/orders",
    component: () => import("/app/apps/valet/src/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: store_45creditMDe6yHOjqUMeta?.name,
    path: "/store-credit",
    component: () => import("/app/apps/valet/src/pages/store-credit.vue").then(m => m.default || m),
    children: [
  {
    name: "store-credit-type___en",
    path: ":type()",
    component: () => import("/app/apps/valet/src/pages/store-credit/[type].vue").then(m => m.default || m)
  },
  {
    name: "store-credit___en",
    path: "",
    meta: index4PvaHPyIn9Meta || {},
    component: () => import("/app/apps/valet/src/pages/store-credit/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "unauthorized___en",
    path: "/unauthorized",
    component: () => import("/app/apps/valet/src/pages/unauthorized.vue").then(m => m.default || m)
  }
]