<template>
  <node-view-wrapper>
    <video controls="" autoplay="" name="media">
      <source :src="node.attrs.videosource" type="video/mp4" />
    </video>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,
};
</script>
