<template>
  <node-view-wrapper>
    <div class="w-[210px]">
      <a
        :href="node.attrs.filesource"
        class=""
        style="text-decoration: none !important"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          class="cursor-pointer bg-neutral-white rounded-lg border border-neutral-300 h-[64px] p-2 text-ellipsis relative"
        >
          <div class="flex items-center">
            <PulseLineFileCopyAlt class="h-4 w-4 text-typography-disabled fill-current mr-2 flex-none" />
            <div class="overflow-ellipsis overflow-hidden">
              <span class="text-typography-primary font-semibold leading-5 text-sm whitespace-nowrap">
                {{ node.attrs.filename }}
              </span>
              <div class="text-typography-disabled uppercase text-xs">{{ node.attrs.filesource.split('.').pop() }}</div>
            </div>
          </div>
          <slot />
        </div>
      </a>
    </div>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,
};
</script>
